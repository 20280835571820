<template>
  <div
    id="situation-center-map"
    class="si-center-map"
  >
    <sc-map-popup-list :settings="popupSettings" />
    <div class="si-center-map-top">
      <r-info-layers
        v-if="mapReady"
        ref="infoLayers"
        :mapgl="mapgl"
        module="sit_center"
      />
      <sc-layer-controller />
    </div>
    <r-map-tools
      v-if="mapgl"
      :tools="['screenshot']"
      :map="mapgl"
    />
  </div>
</template>

<script>
import extent from 'turf-extent'
import { initMap } from './core/'
import { jsonToGeojson } from '@/utils'
import {
  scLayersConfig,
  getFirstSymbolId,
  preparedMapData,
  updateLayerStyle,
  findObjectGeom
} from './configs/'
import debounce from 'lodash.debounce'
import iconCamera from '@/assets/images/r-icons/si-centre/camera.png'
import iconCameraError from '@/assets/images/r-icons/si-centre/camera-error.png'
import iconCameraInactive from '@/assets/images/r-icons/si-centre/camera-inactive.png'
import iconCameraWork from '@/assets/images/r-icons/si-centre/camera-work.png'
import iconCameraActive from '@/assets/images/r-icons/si-centre/camera-active.png'
import iconRoadWork from '@/assets/images/r-icons/si-centre/road-work.png'
import iconRoadWorkActive from '@/assets/images/r-icons/si-centre/road-work-active.png'
import iconRoadEvent from '@/assets/images/r-icons/si-centre/road-event.png'
import iconRoadEventActive from '@/assets/images/r-icons/si-centre/road-event-active.png'
import iconRoadAccident from '@/assets/images/r-icons/si-centre/road-accident.png'
import iconRoadAccidentActive from '@/assets/images/r-icons/si-centre/road-accident-active.png'

import ScMapPopupList from './components/sc-map-popup/sc-map-popup-list'
import ScLayerController from '../widgets/layer-controller/layer-controller'

export default {
  components: {
    ScMapPopupList,
    ScLayerController
  },
  data() {
    return {
      mapgl: null,
      popupSettings: {
        display: 'none',
        layer_id: '',
        top: 0,
        left: 0,
        icons: null,
        colors: null,
        values: []
      },
      eventAvaliableLayers: [],
      controllers: {},
      isReady: false,
      scLayersConfig,
      iconCamera,
      iconCameraActive,
      iconCameraError,
      iconCameraWork,
      iconCameraInactive,
      iconRoadWork,
      iconRoadWorkActive,
      iconRoadEvent,
      iconRoadEventActive,
      iconRoadAccident,
      iconRoadAccidentActive,
      //
      mapReady: false
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token
    },
    theme() {
      return this.$store.state.theme
    },
    mapConfig() {
      return this.$store.state.situationCenter.config.map
    },
    layersData() {
      return this.$store.state.situationCenter.layersData.map
    },
    mapInstanceConfig() {
      return this.$store.state.situationCenter.config
        ? this.$store.state.situationCenter.config.mapLayers
        : []
    },
    objectCards() {
      return this.$store.state.situationCenter.objectCards || []
    },
    layersItems() {
      return Object.keys(this.layersData)
    },
    tileLayersItems() {
      return this.$store.state.situationCenter.config.mapTileLayers
        ? this.$store.state.situationCenter.config.mapTileLayers.map(e => e.id)
        : []
    },
    disabledLayers() {
      return this.$store.state.situationCenter.disabledLayers
    },
    tileLayers() {
      return this.$store.state.situationCenter.config.mapTileLayers || []
    },
    activeSC() {
      return this.$store.state.situationCenter.activeSC || null
    },
    flyToObject() {
      return this.$store.state.situationCenter.flyTo || null
    },
    isSatelliteActive() {
      return this.$store.state.situationCenter.satellite
    }
  },
  watch: {
    theme() {
      this.mapReady = false
      this.init()
    },
    isSatelliteActive() {
      this.mapReady = false
      this.init()
    },
    disabledLayers() {
      this.updateActiveLayers()
      this.updateActiveTileLayers()
      this.updatePopupState()
    },
    layersItems() {
      if (!this.isReady) return
      this.updateActiveLayers()
    },
    objectCards(val) {
      updateLayerStyle(this, val, this.layersItems)
    },
    layersData: {
      handler: function() {
        this.updateData()
      },
      deep: true
    },
    flyToObject: {
      handler: function(val) {
        if (!val) return
        this.flyTo(findObjectGeom(this, val))
        this.$store.commit('CLEAR_SI_CENTER_FLY_TO')
      },
      deep: true
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await initMap(this)

      this.mapgl.once('load', () => {
        this.mapReady = true
      })
    },
    targetClickHandler(info, id, layer_id) {
      this.$store.commit('SI_CENTER_TOGGLE_ITEM', ['objectCards', {
        data: info,
        layer_id,
        id
      }])
    },
    updateData: debounce(function() {
      if (!this.layersItems) return
      this.layersItems.forEach(item => {
        if (this.disabledLayers.indexOf(item) === -1) {
          const sourcename = `${item}_sc_map`
          const features = this.layersData[item]
          const featuresByTypes = {}

          features.forEach(e => {
            if (e.geom) {
              if (!featuresByTypes[e.geom.type]) { featuresByTypes[e.geom.type] = [] }
              featuresByTypes[e.geom.type].push(e)
            }
          })

          Object.keys(featuresByTypes).forEach(e => {
            const source = this.mapgl.getSource(`${sourcename}_${e}`)
            const sourceSymbol = this.mapgl.getSource(`${sourcename}_${e}_s`)
            const data = jsonToGeojson(
              preparedMapData(item, featuresByTypes[e])
            )

            if (!this.layersData[item]) return
            if (source) source.setData(data)
            if (sourceSymbol) sourceSymbol.setData(data)
          })
        }
      })
    }, 2000),
    beforeToggleLayers(layer_id) {
      const sourcename = `${layer_id}_sc_map`
      const features = this.layersData[layer_id]
      const featuresByTypes = {}
      features.forEach(e => {
        if (e.geom) {
          if (!featuresByTypes[e.geom.type]) featuresByTypes[e.geom.type] = []
          featuresByTypes[e.geom.type].push(e)
        }
      })

      Object.keys(featuresByTypes).forEach(e => {
        console.log('addMapLayer', layer_id, e)
        const source = this.mapgl.getSource(`${sourcename}_${e}`)
        if (source && this.disabledLayers.indexOf(layer_id) > -1) {
          this.removeMapLayer(`${sourcename}_${e}`, e, layer_id)
        } else if (!source && this.disabledLayers.indexOf(layer_id) === -1) {
          this.addMapLayer(
            layer_id,
            `${sourcename}_${e}`,
            e,
            featuresByTypes[e]
          )
        }
      })
    },
    removeMapLayer(sourcename, type, layer_id) {
      if (this.mapgl.getLayer(sourcename)) {
        this.mapgl.removeLayer(sourcename)
        if (type === 'Point') {
          if (this.scLayersConfig[`${layer_id}_Point_s`]) {
            this.mapgl.removeLayer(`${sourcename}_s`)
          }
        }
      }
      this.mapgl.removeSource(sourcename)
      if (type === 'Point') {
        if (this.scLayersConfig[`${layer_id}_Point_s`]) {
          this.mapgl.removeSource(`${sourcename}_s`)
        }
      }
    },
    addMapLayer(layer_id, sourcename, type, features) {
      if (!features) return

      const data = jsonToGeojson(preparedMapData(layer_id, features))

      this.mapgl.addSource(sourcename, {
        type: 'geojson',
        data
      })
      if (layer_id === 'network.links') {
        this.mapgl.addLayer(
          {
            id: sourcename,
            source: sourcename,
            ...this.scLayersConfig[`${layer_id}_${type}`]
          },
          getFirstSymbolId(this.mapgl) || ''
        )
      } else {
        this.mapgl.addLayer({
          id: sourcename,
          source: sourcename,
          ...this.scLayersConfig[`${layer_id}_${type}`]
        })
      }

      if (type === 'Point') {
        this.mapgl.addSource(`${sourcename}_s`, {
          type: 'geojson',
          data
        })
        if (this.scLayersConfig[`${layer_id}_${type}_s`]) {
          this.mapgl.addLayer({
            id: `${sourcename}_s`,
            source: `${sourcename}_s`,
            ...this.scLayersConfig[`${layer_id}_${type}_s`]
          })
        }
      }
      this.updateEventsLayers(sourcename)
    },
    toggleTileLayer(layer_id, value) {
      this.mapgl.setLayoutProperty(layer_id, 'visibility', value)
    },
    updateActiveLayers() {
      if (!this.layersItems) return
      this.layersItems.forEach(item => this.beforeToggleLayers(item))
      updateLayerStyle(this, this.objectCards, this.layersItems)
    },
    updateActiveTileLayers() {
      if (!this.tileLayersItems) return
      this.tileLayersItems.forEach(layer_id => {
        const currentProp = this.mapgl.getLayoutProperty(
          layer_id,
          'visibility'
        )
        if (
          currentProp === 'visible' &&
          this.disabledLayers.indexOf(layer_id) > -1
        ) {
          this.mapgl.setLayoutProperty(layer_id, 'visibility', 'none')
        } else if (
          currentProp === 'none' &&
          this.disabledLayers.indexOf(layer_id) === -1
        ) {
          this.mapgl.setLayoutProperty(layer_id, 'visibility', 'visible')
        }
      })
    },
    updatePopupState() {
      if (this.disabledLayers.indexOf(this.popupSettings.layer_id) > -1) {
        this.popupSettings.display = 'none'
        this.popupSettings.values = []
        this.popupSettings.layer_id = ''
      }
    },
    updateEventsLayers(sourcename) {
      if (this.eventAvaliableLayers.indexOf(sourcename) === -1) { this.eventAvaliableLayers.push(sourcename) }
    },
    flyTo(geom) {
      if (!geom) return false
      if (geom.type === 'Point') {
        this.mapgl.flyTo({
          center: geom.coordinates,
          zoom: 14
        })
      } else {
        const bounds = extent(geom)
        this.mapgl.fitBounds(bounds, {
          padding: 40
        })
      }
    }
  }
}
</script>

<style lang="scss">
.si-center-map {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .mapboxgl-canvas {
    &:focus {
      outline: none;
    }
  }

  &-top {
    position: absolute;
    display: flex;
    width: auto;
    height: auto;
    z-index: 10;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);

    .r-info-layers {
      margin-right: 8px;
    }
  }
}
</style>

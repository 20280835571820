<template>
  <div
    v-loading="loading"
    class="pot-1"
  >
    <arrivals-list
      v-if="activeModule !== 'barges'"
      :active-module="activeModule"
    />
    <vehicle-table
      :filters="filters"
      :active-module="activeModule"
      @change-module="activeModule = $event"
    />
  </div>
</template>

<script>
import vehicleTable from './table'
import arrivalsList from './arrivals-list'

import { related, railwayFilters, vehicleFilters, bargesFilters } from './configs'
import { filtersEncoder } from './helpers'

import { sortDataByField } from '@/utils'
import cloneDeep from 'lodash.clonedeep'
import debounce from 'lodash.debounce'

export default {
  components: {
    arrivalsList,
    vehicleTable
  },
  props: {
    isDispatcher: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      loading: false,
      filters: [],
      related
    }
  },
  computed: {
    activeModule: {
      get() {
        return this.$store.state.pot1.activeModule
      },
      set(value) {
        this.$store.commit('SET_POT_FIELD', {
          field: 'activeModule',
          value
        })
      }
    },
    statusListId() {
      return this.$store.state.pot1.statusListIds[this.activeModule] || null
    },
    isAdmin() {
      return this.$store.state.pot1.isAdmin
    },
    updateData() {
      return this.$store.state.pot1.updateData || null
    },
    tmcCategoryId() {
      return (
        this.related.vehicle_categories.data.find(e => e.name === 'ТС с ТМЦ')
          ?.id || null
      )
    }
  },
  watch: {
    activeModule: {
      handler() {
        this.setFilters()
        this.init()
      },
      deep: true
    },
    updateData(v) {
      if (!v) return

      this.init()

      this.$store.commit('SET_POT_FIELD', {
        field: 'updateData',
        value: false
      })
    }
  },
  async created() {
    try {
      this.loading = true
      this.$store.commit('SET_POT_FIELD', {
        field: 'isAdmin',
        value: this.isDispatcher
      })
      await this.loadRelated()
      await this.setFilters()
      this.init(true)
    } finally {
      this.loading = false
    }
  },
  methods: {
    setFilters() {
      const compName = this.isAdmin ? 'dispatcher' : 'logistic'
      const filterName = !this.activeModule || !this.isAdmin
        ? 'dispatcher_vehicles'
        : `${compName}_${this.activeModule}`
      const userFilters = this.$store.state.pot1.filters?.[filterName]
      let initFilters

      switch (this.activeModule) {
        case 'vehicles':
          initFilters = vehicleFilters
          break
        case 'railway':
          initFilters = railwayFilters
          break
        case 'barges':
          initFilters = bargesFilters
          break
      }

      this.filters = initFilters?.map(f => {
        const userItem = userFilters?.find(uf => uf.id === f.id)
        const related = this.related[f.source]
        let filter = userItem || f

        if (related) {
          filter = {
            ...filter,
            prop: related.data.map(d => {
              const fValue = !!userItem?.prop?.find(fi => fi.id === d.id)?.value

              return {
                ...d,
                value: fValue
              }
            })
          }
        }

        return {
          ...filter
        }
      })
    },
    init: debounce(async function() {
      try {
        this.loading = true

        if (this.activeModule === 'vehicles') {
          await this.loadStatusList()
          await this.loadVehicleList()
        } else if (this.activeModule === 'railway') {
          await this.loadStatusList()
          await this.loadRailway()
        } else if (this.activeModule === 'barges') {
          await this.loadBarges()
        } else {
          console.log(`Unknown datatype = ${this.activeModule}`)
        }
      } finally {
        this.loading = false
      }
    }, 100),
    getCargoeValues(item, field, type = null, format = 'DD.MM.YYYY HH:mm') {
      const actual = item.cargoes.filter(({ receiving_date_fact }) =>
        !receiving_date_fact
      )
      if (!actual[0]) return '—'

      return (
        actual
          .reduce((acc, curr) => {
            if (curr[field]) {
              switch (type) {
                case 'date': {
                  const val = curr[field]?.name || curr[field]
                  acc.push(this.$rDate.format(val, format))
                  break
                }
                default:
                  acc.push(curr[field]?.name || curr[field])
                  break
              }
            }
            return acc
          }, [])
          .join(', ') || '—'
      )
    },
    async loadBarges() {
      try {
        const config = {
          where: [],
          only: [
            'id',
            'name',
            'number'
          ],
          include: {
            cargoes: {
              where: [{ field: 'receiving_date_fact', op: '=', value: null }],
              only: ['packing_list_no', 'id', 'subproject_id', 'receiving_date', 'forecast_delivery', 'receiving_date_fact'],
              include: { contractor: {}, sender: {}, subproject: { only: ['name'] } }
            },
            comments: { order: { created_at: 'desc' } }
          }
        }

        config.where = filtersEncoder(this.filters)

        const params = 'data=barges'

        const url = `vehicles_cargo?${params}&config=${JSON.stringify(config)}`
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })

        this.$store.commit('SET_POT_FIELD', {
          field: 'potVehicleList',
          value: data?.map(e => {
            e.cargoes = e.cargoes.filter(({ receiving_date_fact }) =>
              !receiving_date_fact
            )
            return {
              ...e,
              sender: this.getCargoeValues(e, 'sender'),
              packing_list_no: this.getCargoeValues(e, 'packing_list_no'),
              subproject: this.getCargoeValues(e, 'subproject'),
              comments: e.comments?.[0]?.body?.substring(0, 15) || '—'
            }
          })
        })
      } catch (e) {
        throw new Error(e)
      }
    },
    async loadRailway() {
      try {
        const config = {
          where: [],
          only: [
            'id',
            'carriage_no',
            'current_station',
            'destination_station',
            'arrival_forecast',
            'waybill_no',
            'status_pre_id'
          ],
          include: {
            cargoes: {
              where: [{ field: 'receiving_date_fact', op: '=', value: null }],
              only: ['packing_list_no', 'id', 'subproject_id', 'receiving_date', 'forecast_delivery', 'receiving_date_fact'],
              include: { contractor: {}, sender: {}, subproject: { only: ['name'] } }
            },
            comments: { order: { created_at: 'desc' } }
          }
        }

        config.where = filtersEncoder(this.filters)

        let params = '&arrival_forecast_order=asc&data=zd'

        const forecastFilter = this.filters?.find(e => e.id === 'arrival_forecast')

        if (forecastFilter.active) {
          const { from, to } = cloneDeep(forecastFilter.prop.interval)
          params += `&arrival_forecast_filter=${this.$rDate.format(from, 'YYYY-MM-DDTHH:mm:ss')}/${this.$rDate.format(to, 'YYYY-MM-DDTHH:mm:ss')}`
        }

        const url = `vehicles_cargo?${params}&config=${JSON.stringify(config)}`
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })

        this.$store.commit('SET_POT_FIELD', {
          field: 'potVehicleList',
          value: data?.map(e => {
            e.pot_status_id = e.status_pre_id
            e.arrival_forecast = e.arrival_forecast
              ? this.$rDate.zeroing(e.arrival_forecast).format('DD.MM.YYYY')
              : '—'
            e.cargoes = e.cargoes.filter(({ receiving_date_fact }) =>
              !receiving_date_fact
            )
            return {
              ...e,
              delivery_forecast: e.delivery_forecast
                ? this.$rDate.zeroing(e.delivery_forecast).format('DD.MM.YYYY')
                : '—',
              sender: this.getCargoeValues(e, 'sender'),
              packing_list_no: this.getCargoeValues(e, 'packing_list_no'),
              subproject: this.getCargoeValues(e, 'subproject'),
              comments: e.comments?.[0]?.body?.substring(0, 15) || '—'
            }
          })
        })
      } catch (e) {
        throw new Error(e)
      }
    },
    async loadVehicleList() {
      const { config } = cloneDeep(this.related.vehicles)

      config.where.push(...filtersEncoder(this.filters))
      config.include.comments = { order: { created_at: 'desc' } }

      try {
        const url = `vehicles_cargo?data=vehicles&config=${JSON.stringify(config)}`

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })

        const potVehicles = Object.values(data).map(v => {
          let minDate
          v.cargoes ||= []
          v.cargoes.forEach(c => {
            const query = this.$rDate.format(c.forecast_delivery, 'x')

            if (!minDate || minDate > query) {
              minDate = query
            }
          })

          return {
            id: v.id,
            driver_full_name: v.driver_full_name || '—',
            driver_phone: v.driver_phone || '—',
            reg_number: v.reg_number || '—',
            pot_status_id: v.pot_status_id || '—',
            checkpoint_id: v.checkpoint_id || '—',
            warehouse_id: v.warehouse_id || '—',
            forecast_delivery: this.getForecastValue(v, minDate),
            contractor: this.getCargoeValues(v, 'sender'),
            nomenclature_group: this.getCargoeValues(v, 'nomenclature_group'),
            packing_list_no: this.getCargoeValues(v, 'packing_list_no'),
            subproject: this.getCargoeValues(v, 'subproject'),
            comments: v.comments?.[0]?.body?.substring(0, 15) || '—'
          }
        })
        this.$store.commit('SET_POT_FIELD', {
          field: 'potVehicleList',
          value: potVehicles
        })
      } catch (e) {
        throw new Error(e)
      }
    },
    getForecastValue(item, minDate) {
      if (!item.cargoes[0]) return '—'

      const date = item.cargoes.find(c => {
        const query = this.$rDate.format(c.forecast_delivery, 'x')
        return minDate === query
      })?.forecast_delivery

      return date ? this.$rDate.format(date, 'DD.MM.YYYY') : '—'
    },
    async loadRelated() {
      for (const r in this.related) {
        const urlName = this.related[r].urlName
        const source_id = this.$store.state.services[urlName]
        if (!source_id) return

        try {
          const config = cloneDeep(this.related[r].config)

          if (r === 'vehicles') {
            config.where.push({
              field: 'categories.id',
              op: '=',
              value: this.tmcCategoryId
            })
            config.where.push({
              field: 'pot_status_id',
              op: '=',
              value: null
            })
          }

          const { data } = await this.$store.dispatch('GET_REQUEST', {
            url: `objectInfo/${source_id}?config=${JSON.stringify(config)}`
          })

          if (r === 'pot_status_id') {
            this.related[r].data = Object.values(data)?.map((s, i) => ({
              ...s,
              name: (s.ordered / 10) + '. ' + s.name,
              ordered: s.ordered || i
            })).sort((a, b) => {
              if (a.ordered > b.ordered) return 1
              if (a.ordered < b.ordered) return -1
              return 0
            })
          } else {
            this.related[r].data = Object.values(data)
          }

          this.filters = this.filters.map(f => {
            let filter = f
            if (f.source === r) {
              filter = {
                ...f,
                prop: this.related[r].data.map(d => {
                  const fValue = !!f?.prop?.find(fi => fi.id === d.id)?.value

                  return {
                    ...d,
                    value: fValue
                  }
                })
              }
            }
            return {
              ...filter
            }
          })
        } catch (e) {
          throw new Error(e)
        }
      }
      this.$store.commit('SET_POT_FIELD', {
        field: 'related',
        value: this.related
      })
    },
    async loadStatusList() {
      try {
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.statusListId}`
        })

        const orders = {
          'В пути': 10,
          'Будет в течение 48 часов': 20,
          'Прибыл на станцию назначения': 30
        }
        const formatedList = Object.values(data).map((s, i) => {
          return {
            name: s.name,
            id: s.id,
            ordered: s.ordered || orders[s.name] || (i + 1) * 10
          }
        })

        const value = sortDataByField(formatedList, 'ordered')

        this.$store.commit('SET_POT_FIELD', {
          field: 'statusList',
          value
        })
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss">
.pot-1 {
  height: calc(100% - 1rem);
  width: 100%;
  display: grid;
  align-content: start;
  position: relative;
  background-color: var(--bg_surface);
  overflow: auto;
}
</style>

import { jsonToGeojson } from '@/utils'
import { lineString } from '@turf/helpers'
import along from '@turf/along'
import turfLength from '@turf/length'
import bearing from '@turf/bearing'
import { layersConfig } from '@/components/monitoring/map/config'

export class LiveTrackController {
  constructor(mapgl) {
    this.mapgl = mapgl
    this.trackRenderTimer = null
    this.tracksData = {}
    this.events = {}
    this.vehicles = {}
    this.uid_by_id = {}
  }

  addTrackData(uid, lat, lon, course, speed, gps_time) {
    if (!(uid in this.tracksData)) {
      this.tracksData[uid] = []
    }

    const currentTracks = this.tracksData[uid]
    if (currentTracks.length > 3000) {
      currentTracks.shift()
    }
    currentTracks.push({
      uid: uid,
      lat: lat,
      lon: lon,
      course: course,
      speed: speed,
      gps_time: gps_time
    })
  }

  addEvents(event) {
    // {
    //   "event_time": "2024-10-05T16:11:02.000+00:00",
    //   "event_type": "over_speed",
    //   "event_attrs": {
    //   "speed": 119,
    //     "over_value": 19,
    //     "speed_limit": 100
    //    },
    //   "vehicle_id": "3817e35a-7b04-43ba-8ec6-1fb742fe6ec8",
    //   "geom": ,
    //   "description": "Превышение на 19 км/ч",
    //   "reg_number": "5057"
    // }
    const uid = this.uid_by_id[event.vehicle_id]

    if (uid) {
      if (!(uid in this.events)) {
        this.events[uid] = []
      }

      const currentEvents = this.events[uid]
      if (currentEvents.length > 3000) {
        currentEvents.shift()
      }
      currentEvents.push(event)
    }
  }

  setVehicleInfo(vehicleInfo) {
    Object.values(vehicleInfo).forEach(vehicle => {
      const { track_data_id, current_point, geom, ...rest } = vehicle
      this.uid_by_id[rest.id] = track_data_id
      this.vehicles[track_data_id] = { ...rest, geom: geom || current_point }
    })
  }

  startVehicleAnimation() {
    this.trackRenderTimer = setInterval(() => {
      this.renderVehicles()
    }, 50)
  }

  stopVehicleAnimation() {
    if (this.trackRenderTimer) {
      clearInterval(this.trackRenderTimer)
    }
  }

  renderVehicles() {
    const interpolateData = Object.keys(this.vehicles)
      .map(uid => {
        if (this.tracksData[uid]) {
          const p = this.getTrackPoint(uid)
          return { ...this.vehicles[uid], ...p }
        }
        return this.vehicles[uid]
      })
      .filter(item => item.geom)

    const source = this.mapgl.getSource('vehicles')

    if (source && interpolateData.length > 0) {
      const res = jsonToGeojson(interpolateData)
      source.setData(res)
    }
  }

  getTrackPoint(uid) {
    const currentTime = Date.now() - 15000
    const currentTracks = this.tracksData[uid]

    if (currentTracks && currentTracks.length > 2) {
      for (let i = currentTracks.length - 1; i >= 1; i--) {
        const first_time = currentTracks[i - 1].gps_time * 1000
        const last_time = currentTracks[i].gps_time * 1000

        if (currentTime >= first_time && currentTime <= last_time) {
          const line = lineString([
            [currentTracks[i - 1].lon, currentTracks[i - 1].lat],
            [currentTracks[i].lon, currentTracks[i].lat]
          ])
          const fraction = (currentTime - first_time) / (last_time - first_time)

          return {
            gps_time: currentTime,
            course: bearing(
              [currentTracks[i - 1].lon, currentTracks[i - 1].lat],
              [currentTracks[i].lon, currentTracks[i].lat]
            ),
            speed: currentTracks[i - 1].speed,
            geom: along(line, turfLength(line) * fraction).geometry
          }
        }
      }
    }
    return {}
  }

  setLiveTrackLayer(id, base_layer_name) {
    const uid = this.uid_by_id[id]

    if (uid) {
      const tracksArray = this.tracksData[uid]

      if (tracksArray && tracksArray.length > 1) {
        const source = this.mapgl.getSource(base_layer_name)

        const line_tracks = []
        for (let i = 0; i < tracksArray.length - 1; i++) {
          line_tracks.push({
            time: tracksArray[i + 1].gps_time,
            gps_time: tracksArray[i + 1].gps_time,
            speed: tracksArray[i + 1].speed,
            geom: {
              type: 'LineString',
              coordinates: [
                [tracksArray[i].lon, tracksArray[i].lat],
                [tracksArray[i + 1].lon, tracksArray[i + 1].lat]
              ]
            }
          })
        }

        const track = jsonToGeojson(line_tracks)

        if (source) {
          source.setData(track)
        } else {
          this.mapgl.addSource(base_layer_name, {
            type: 'geojson',
            data: track
          })
          this.mapgl.addLayer(
            {
              id: `${base_layer_name}-arrow`,
              source: base_layer_name,
              type: 'symbol',
              layout: {
                'symbol-placement': 'line',
                'text-field': '▶',
                'text-size': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  12,
                  10,
                  22,
                  12
                ],
                'symbol-spacing': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  12,
                  32,
                  22,
                  45
                ],
                'text-keep-upright': false,
                'text-allow-overlap': true,
                'icon-allow-overlap': true
              },
              paint: {
                'text-color': '#ccc',
                'text-halo-color': '#ffffff',
                'text-halo-width': 0.8
              }
            },
            'vehicles'
          )
          this.mapgl.addLayer(
            {
              id: base_layer_name,
              source: base_layer_name,
              type: 'line',
              layout: {
                'line-join': 'round',
                'line-cap': 'round'
              },
              paint: {
                'line-color': [
                  'case',
                  ['boolean', ['feature-state', 'hover'], false],
                  '#55b77e',
                  [
                    'step',
                    ['get', 'speed'],
                    'rgb(180,220,177)',
                    40,
                    'rgba(153,213,148, 1)',
                    60,
                    'rgb(236,209,158)',
                    70,
                    'rgb(248,216,127)',
                    90,
                    'rgba(252,141,89, 1)',
                    110,
                    'rgba(213,62,79, 1)'
                  ]
                ],
                'line-width': 6,
                'line-opacity': 0.9
              }
            },
            'vehicles'
          )
        }
      }
    }
  }

  disableLiveTrack(base_layer_name) {
    if (this.mapgl.getLayer(base_layer_name)) {
      this.mapgl.removeLayer(base_layer_name)
    }
    if (this.mapgl.getLayer(`${base_layer_name}-arrow`)) {
      this.mapgl.removeLayer(`${base_layer_name}-arrow`)
    }
    if (this.mapgl.getSource(base_layer_name)) {
      this.mapgl.removeSource(base_layer_name)
    }
  }

  addEventLayers(id) {
    const uid = this.uid_by_id[id]

    if (uid) {
      const eventsSourceId = 'vehicleEvents'
      const source = this.mapgl.getSource(eventsSourceId)

      const data = jsonToGeojson(this.events[uid] || [])

      if (data.length === 0) {
        this.removeEvents()
      } else if (source) {
        source.setData(data)
      } else {
        this.mapgl.addSource(eventsSourceId, {
          type: 'geojson',
          data: data
        })
        const styles = layersConfig.events

        this.mapgl.addLayer({
          id: eventsSourceId,
          source: eventsSourceId,
          type: 'line',
          ...styles
        })
      }
    }
  }

  removeEvents() {
    const eventsSourceId = 'vehicleEvents'

    if (this.mapgl.getLayer(eventsSourceId)) {
      this.mapgl.removeLayer(eventsSourceId)
    }
    if (this.mapgl.getSource(eventsSourceId)) {
      this.mapgl.removeSource(eventsSourceId)
    }
  }
}

<template>
  <div class="mt-track">
    <div class="mt-track__filters">
      <track-filters
        :interval="interval"
        :loading="loadingTrack"
        :show="showTrack"
        @load="changeFilters"
        @toggle="toggleTrack"
      />
    </div>
    <div
      v-show="showTrack"
      class="mt-track__compare"
    >
      <track-compare
        ref="trackCompare"
        @change="changeSecondTrack"
      />
    </div>
    <div
      v-if="showTrack && chartData"
      class="mt-track__chart"
    >
      <r-block no-padding>
        <r-block
          no-padding
        >
          <r-text type="caption">
            Типы событий
          </r-text>
          <el-select
            v-model="eventsFilter"
            class="r-select"
            collapse-tags
            filterable
            clearable
            multiple
          >
            <el-option
              v-for="eventType in eventsTypes"
              :key="eventType.id"
              :value="eventType.id"
              :label="eventType.title"
            >
              {{ eventType.title }}
            </el-option>
          </el-select>
        </r-block>
        <r-block no-padding>
          <r-text type="caption">
            Цвет первого трека
          </r-text>
          <el-color-picker
            :value="mainColor"
            class="r-color-picker__picker"
            popper-class="r-color-picker-dropdown"
            color-format="hex"
            @active-change="changeColor('main', $event)"
          />
        </r-block>
        <r-block
          v-if="secondTrack"
          no-padding
        >
          <r-text type="caption">
            Цвет второго трека
          </r-text>
          <el-color-picker
            :value="secondColor"
            class="r-color-picker__picker"
            popper-class="r-color-picker-dropdown"
            color-format="hex"
            @active-change="changeColor('second', $event)"
          />
        </r-block>
      </r-block>
      <charts
        no-control
        data-zoom
        time-axis
        :data="chartData"
        :colors="[mainColor, secondColor]"
        chart-id="mt_track_chart"
      />
    </div>
  </div>
</template>

<script>
import TrackFilters from './track/track-filters'
import TrackCompare from './track/track-compare'
import charts from '@/components/charts/charts'
import { notifyFactory, jsonToGeojson } from '@/utils'
import debounce from 'lodash.debounce'
import { eventsTranslate } from '../../map/config'

export default {
  components: {
    TrackFilters,
    TrackCompare,
    charts
  },
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      source: '40c30689-7918-4117-97e4-39222d6751aa',
      loadingTrack: false,
      showTrack: false,
      secondTrack: null,
      tracksChartData: {
        main: null,
        second: null
      },
      eventsTypes: Object.entries(eventsTranslate).map(([id, title]) => ({ id, title })),
      eventsFilter: Object.keys(eventsTranslate)
    }
  },
  computed: {
    interval() {
      return this.$store.state.monitoring.eventTime.interval
    },
    mainColor: {
      get() {
        return this.$store.state.monitoring.trackColors.main
      },
      set(value) {
        this.$store.commit('SET_MT_FIELD', {
          field: 'trackColors.main',
          value
        })
      }
    },
    secondColor: {
      get() {
        return this.$store.state.monitoring.trackColors.second
      },
      set(value) {
        this.$store.commit('SET_MT_FIELD', {
          field: 'trackColors.second',
          value
        })
      }
    },
    cardId() {
      return this.$store.state.monitoring.cardId
    },
    chartData() {
      const { main, second } = this.tracksChartData
      const data = {
        data: []
      }

      if (main) data.data.push(main)
      data.data.push(second || {})

      if (main || second) return data

      return null
    },
    mainTrackGeom() {
      return this.$store.state.monitoring.mainTrackGeom || null
    },
    mainTrackEvents() {
      return this.$store.state.monitoring.mainTrackEvents || null
    },
    secondTrackGeom() {
      return this.$store.state.monitoring.secondTrackGeom || null
    },
    secondTrackEvents() {
      return this.$store.state.monitoring.secondTrackEvents || null
    }
  },
  watch: {
    interval() {
      if (!this.showTrack) return

      this.changeFilters()
    },
    eventsFilter() {
      this.changeFilters()
    }
  },
  created() {
    if (this.mainTrackGeom?.length) {
      this.loadTrackFromStore('main')
    }

    if (this.secondTrackGeom?.length) {
      this.loadTrackFromStore('second')
      this.secondTrack = this.$store.state.monitoring.selectedSecondTrackId
    }
  },
  methods: {
    changeColor: debounce(function(type, color) {
      switch (type) {
        case 'main':
          this.mainColor = color
          break
        case 'second':
          this.secondColor = color
          break
      }
      this.changeFilters()
    }, 128),
    toggleTrack() {
      console.log(this.cardId)
      if (this.showTrack) {
        this.removeTrack('main')
        this.secondTrack = null
      } else {
        this.loadTrack(this.cardId, 'main')
      }
    },
    changeFilters() {
      this.loadTrack(this.cardId)

      if (this.secondTrack) {
        this.loadTrack(this.secondTrack, 'second')
      }
    },
    changeSecondTrack(id) {
      if (id) {
        this.loadTrack(id, 'second')
        this.secondTrack = id
      } else {
        this.removeTrack('second')
        this.secondTrack = null
        this.tracksChartData.second = null
      }

      this.$store.commit('SET_MT_FIELD', {
        field: 'selectedSecondTrackId',
        value: id
      })
    },
    async loadTrack(id, type = 'main') {
      try {
        this.loadingTrack = true

        const { from, to } = this.interval
        const fromUp = new Date(from)
        const fromInit = fromUp.setSeconds(0)
        const toUp = new Date(to)
        const toInit = toUp.setSeconds(59)
        const fromValue = this.$rDate.format(fromInit, 'iso')
        const toValue = this.$rDate.format(toInit, 'iso')

        const config = {
          order: { gps_time: 'asc' },
          only: ['gps_time', 'geom', 'speed'],
          where: [
            { func: 'st_x', field: 'geom', op: '!=', value: 0 },
            {
              field: 'vehicle_id',
              op: '=',
              value: id
            },
            {
              field: 'gps_time',
              op: '>',
              value: fromValue
            },
            {
              field: 'gps_time',
              op: '<',
              value: toValue
            }
          ]
        }
        const url = `objectInfo/${this.source}?array=true&config=${JSON.stringify(config)}`

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })
        const eventsUrl = `car_event?from=${fromValue}&to=${toValue}&vehicle_id=${id}`
        const { data: events } = await this.$store.dispatch('GET_REQUEST', {
          url: eventsUrl
        })
        let maxSpeed = 0
        const parsedData = this.getGeojsonData(data)?.filter(p => {
          if (maxSpeed < p.properties.speed) {
            maxSpeed = p.properties.speed
          }
          return p.properties.valid
        })
        const filteredEvents = events?.filter(e => e.event_geom && this.eventsFilter?.includes(e.event_type))
        const parsedEvents = this.parseEventGeojson(filteredEvents, maxSpeed)

        if (parsedData?.length) {
          // set geojson
          this.$store.commit('SET_MT_FIELD', {
            field: `${type}TrackGeom`,
            value: parsedData
          })
          this.$store.commit('SET_MT_FIELD', {
            field: `${type}TrackEvents`,
            value: parsedEvents
          })

          // set chart data
          this.$set(this.tracksChartData, type, this.getChartData(parsedData, type))

          this.showTrack = true
        } else {
          const title = 'Данные по треку отсутствуют'
          const message =
            'Информация о перемещении ТС за указанный период отсутствует'
          this.$notify(notifyFactory('info', title, message))

          this.$store.commit('SET_MT_FIELD', {
            field: `${type}TrackGeom`,
            value: null
          })
        }
      } catch (error) {
        throw new Error(error)
      } finally {
        this.loadingTrack = false
      }
    },
    loadTrackFromStore(type) {
      const geomData = this[`${type}TrackGeom`]

      this.$set(this.tracksChartData, type, this.getChartData(geomData, type))

      this.showTrack = true
    },
    removeTrack(type) {
      if (type === 'second') {
        this.$store.commit('SET_MT_FIELD', {
          field: 'secondTrackGeom',
          value: null
        })

        return
      }

      this.showTrack = false
      this.tracksChartData.main = null
      this.tracksChartData.second = null
      this.secondTrack = null

      this.$store.commit('SET_MT_FIELD', {
        field: 'mainTrackGeom',
        value: null
      })
      this.$store.commit('SET_MT_FIELD', {
        field: 'mainTrackEvents',
        value: null
      })
      this.$store.commit('SET_MT_FIELD', {
        field: 'secondTrackGeom',
        value: null
      })
      this.$store.commit('SET_MT_FIELD', {
        field: 'secondTrackEvents',
        value: null
      })
      this.$store.commit('SET_MT_FIELD', {
        field: 'selectedSecondTrackId',
        value: null
      })

      this.$refs.trackCompare.clearCompare()
    },
    getChartData(data, type) {
      if (type.includes('Events')) {
        return {
          title: `Событие (${type === 'mainEvents' ? '1' : '2'})`,
          data: data
            ?.map(p => [
              this.$rDate.format(p.properties.time, 'YYYY-MM-DD HH:mm:ss'),
              Number(p.properties.speed?.toFixed(1))
            ])
        }
      } else {
        return {
          title: `Скорость (${type === 'main' ? '1' : '2'})`,
          data: data
            .map(p => [
              this.$rDate.format(p.properties.time, 'YYYY-MM-DD HH:mm:ss'),
              Number(p.properties.speed?.toFixed(1))
            ])
        }
      }
    },
    getGeojsonData(data) {
      if (!data) return []
      const array = Object.values(data)

      const length = array.length

      if (!length) return []

      return array
        .map((p, i) => {
          const nextItem = array[i + 1]

          return {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [p.geom.coordinates, nextItem?.geom?.coordinates]
            },
            properties: {
              time: p.gps_time,
              speed: Number(p.speed?.toFixed(1)),
              valid: !!nextItem?.geom?.coordinates
            }
          }
        })
    },
    parseEventGeojson(data, maxSpeed) {
      if (!data) return []

      const length = data.length

      if (!length) return []

      return jsonToGeojson(data
        .map(({ event_time, description, event_type, event_geom }) => {
          return {
            geom: event_geom,
            time: event_time,
            event_type,
            description,
            speed: maxSpeed
          }
        }))
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-track {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-areas: 'filters compare' 'chart chart';
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem 1rem;
  align-self: start;

  &__filters {
    grid-area: filters;
    justify-self: start;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    align-items: center;
  }

  &__compare {
    align-self: end;
    grid-area: compare;
  }

  &__chart {
    height: 196px;
    width: 100%;
    grid-area: chart;
    display: grid;
    grid-template-columns: 180px 1fr;
    grid-gap: 1rem;

    .charts {
      height: 196px;
    }
  }
}
</style>

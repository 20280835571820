import { jsonToGeojson, rDate } from '@/utils'
import { layersConfig } from '../config'
import { getVehiclesRequestConfig } from '../helpers'
import { LiveTrackController } from '@/libs/map-draw/controllers/live-track-controller'

export class VehicleController {
  constructor(map) {
    this.map = map
    this.$store = map.$store
    this.mapgl = map.mapgl
    this.data = {}
    this.socketHandler = this.socketHandler.bind(this)
    this.socketEventsHandler = this.socketEventsHandler.bind(this)
    this.liveTrackController = new LiveTrackController(this.mapgl)
    this.trackCardRenderTimer = null
  }

  stopVehicleAnimation() {
    this.liveTrackController.stopVehicleAnimation()
  }

  getFilteredByStatus(vehicles) {
    const { items } = this.$store.state.monitoring.statusFilter
    const activeItems = items.filter(item => item.active)

    items.forEach(item => this.map.$set(item, 'count', 0))

    return vehicles.filter(({ retranslation_status }) => {
      let isVehicleStatusActive = false

      activeItems.forEach(status => {
        if (status.values.includes(retranslation_status)) {
          this.map.$set(status, 'count', status.count + 1)
          isVehicleStatusActive = true
        }
      })

      return isVehicleStatusActive
    })
  }

  async loadVehicles() {
    try {
      this.map.vehiclesLoading = true
      this.data = {}

      const config = getVehiclesRequestConfig(
        this.$store,
        null,
        this.$store.state.monitoring.filters
      )

      const uri = `vehicles_map?config=${JSON.stringify(config)}`
      const { data } = await this.$store.dispatch('GET_REQUEST', { url: uri })

      let filteredData = data?.filter(data => !!data.current_point) || []

      // set vehicle statuses count
      filteredData = this.getFilteredByStatus(filteredData)

      // set to store
      filteredData.forEach(vehicle => {
        this.data[vehicle.track_data_id] = {
          ...vehicle,
          geom: vehicle.current_point,
          current_point: null
        }
      })

      this.liveTrackController.setVehicleInfo(filteredData)

      this.$store.commit('SET_MT_FIELD', {
        field: 'vehiclesCount',
        value: filteredData?.length || 0
      })
      this.$store.commit('SET_MT_FIELD', {
        field: 'vehiclesWithoutGeomCount',
        value: data ? data?.length - (filteredData?.length || 0) : 0
      })

      this.$store.commit('SET_MT_FIELD', {
        field: 'vehicles',
        value: filteredData
      })
    } catch (error) {
      throw new Error(error)
    } finally {
      setTimeout(() => {
        this.map.vehiclesLoading = false
      }, 0)
    }
  }

  getLayerData() {
    const { data } = this

    return jsonToGeojson(Object.values(data))
  }

  async addVehicles() {
    await this.loadVehicles()
    const data = this.getLayerData()

    if (this.mapgl.getSource('vehicles')) {
      return
    }

    this.startSocketConnection()

    this.mapgl.addSource('vehicles', {
      type: 'geojson',
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 36,
      data
    })

    // add clusters
    this.mapgl.addLayer({
      id: 'vehicles-clusters',
      ...layersConfig.vehicles_clusters
    })
    this.mapgl.addLayer({
      id: 'vehicles-cluster-count',
      ...layersConfig.vehicles_clusters_count
    })

    this.mapgl.addLayer({
      id: 'vehicles',
      source: 'vehicles',
      ...layersConfig.vehicles
    })

    this.addHandlers()
    this.liveTrackController.startVehicleAnimation()
  }

  removeVehicles() {
    if (this.mapgl.getLayer('vehicles')) {
      this.mapgl.removeLayer('vehicles')
    }
    if (this.mapgl.getSource('vehicles')) {
      this.mapgl.removeSource('vehicles')
    }

    // this.stopSocketConnection()
  }

  async updateVehicles() {
    const source = this.mapgl.getSource('vehicles')

    if (source) {
      await this.loadVehicles()
      const data = this.getLayerData()

      source.setData(data)
    }
  }

  socketHandler(data) {
    for (let i = 0; i < data.length; i++) {
      const track = data[i]
      const splits = track.split(';')

      const uid = parseInt(splits[0])
      const lat = parseFloat(splits[1])
      const lon = parseFloat(splits[2])
      const course = parseFloat(splits[3])
      const gps_time = parseInt(splits[4])
      const speed = parseFloat(splits[5])

      const current = this.data[uid]

      if (current) {
        current.course = course
        current.gps_time = gps_time
        current.speed = speed
        current.geom.coordinates[0] = lon
        current.geom.coordinates[1] = lat
        if (this.$store.state.monitoring.cardId === current.id) {
          this.$store.commit('SET_MT_FIELD', {
            field: 'cardLocation',
            value: current.geom
          })
        }
        this.liveTrackController.addTrackData(
          uid,
          lat,
          lon,
          course,
          speed,
          gps_time
        )
      }
    }
  }

  socketEventsHandler(data) {
    for (let i = 0; i < data.length; i++) {
      const event = data[i]

      this.liveTrackController.addEvents(event)
    }
  }

  startSocketConnection() {
    this.map.$socket.on('track_data', this.socketHandler)
    this.map.$socket.on('live_events', this.socketEventsHandler)
  }

  stopSocketConnection() {
    this.map.$socket.off('track_data', this.socketHandler)
    this.map.$socket.off('live_events', this.socketEventsHandler)
  }

  enableTrackLine(id, baseLayerName) {
    this.liveTrackController.setLiveTrackLayer(id, baseLayerName)
    this.liveTrackController.addEventLayers(id, `${baseLayerName}-events`)
  }

  disabledTrackLine(baseLayerName) {
    this.liveTrackController.disableLiveTrack(baseLayerName)
    this.liveTrackController.removeEvents(`${baseLayerName}-events`)
  }

  addHandlers() {
    // vehicles handlers
    this.mapgl.on('click', e => {
      this.toggleActiveStyling(undefined)
      this.toggleVehicleCard(undefined)
    })

    this.mapgl.on('mousemove', 'vehicles', e => {
      this.mapgl.getCanvas().style.cursor = 'pointer'

      const { x, y } = e.point
      const [feature] = e.features
      const { properties } = feature
      const { gps_time, speed, reg_number, contractor } = properties

      const parsedContractor = JSON.parse(contractor)

      const values = [
        {
          key: 'ГРЗ',
          value: reg_number
        },
        {
          key: 'Подрядчик',
          value: parsedContractor ? parsedContractor.name : 'Не указан'
        },
        {
          key: 'Время GPS',
          value: rDate.format(gps_time, 'DD.MM.YYYY • HH:mm:ss')
        },
        {
          key: 'Скороксть',
          value: `${speed} км/ч`
        }
      ]

      if (this.map.popupSettings) {
        this.map.popupSettings.top = y - 38
        this.map.popupSettings.left = x + 10 + (this.map.popupSettingsLeftLag || 0)
        this.map.popupSettings.display = 'block'
        this.map.popupSettings.values = values
      }

      this.enableTrackLine(e.features[0].properties.id, 'vehicleTracks')
    })

    this.mapgl.on('mouseleave', 'vehicles', e => {
      this.mapgl.getCanvas().style.cursor = ''
      if (this.map.popupSettings) {
        this.map.popupSettings.display = 'none'
        this.map.popupSettings.values = []
      }
      this.disabledTrackLine('vehicleTracks')
    })

    this.mapgl.on('click', 'vehicles', e => {
      const { id, track_data_id } = e.features[0].properties

      this.toggleActiveStyling(id)
      this.toggleVehicleCard(id)

      this.$store.commit('SET_MT_FIELD', {
        field: 'cardId',
        value: id
      })
      this.$store.commit('SET_MT_FIELD', {
        field: 'trackDataId',
        value: track_data_id
      })
    })

    // clusters handlers
    this.mapgl.on('mouseenter', 'vehicles-clusters', () => {
      this.mapgl.getCanvas().style.cursor = 'pointer'
    })
    this.mapgl.on('mouseleave', 'vehicles-clusters', () => {
      this.mapgl.getCanvas().style.cursor = ''
    })
    this.mapgl.on('click', 'vehicles-clusters', e => {
      const features = this.mapgl.queryRenderedFeatures(e.point, {
        layers: ['vehicles-clusters']
      })
      const clusterId = features[0].properties.cluster_id
      this.mapgl
        .getSource('vehicles')
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return

          this.mapgl.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          })
        })
    })
  }

  toggleVehicleCard(id) {
    if (id) {
      if (this.trackCardRenderTimer) {
        clearInterval(this.trackCardRenderTimer)
      }
      this.trackCardRenderTimer = setInterval(() => {
        this.enableTrackLine(id, 'vehicleTracks-1')
      }, 1000)
    } else {
      if (this.trackCardRenderTimer) {
        clearInterval(this.trackCardRenderTimer)
      }
      this.disabledTrackLine('vehicleTracks-1')
    }
  }

  toggleActiveStyling(id) {
    const iconDefaultValue = layersConfig.vehicles.layout['icon-image']

    if (id) {
      const iconValue = [
        'match',
        ['get', 'id'],
        id,
        'vehicle-selected',
        iconDefaultValue
      ]

      this.mapgl.setLayoutProperty('vehicles', 'icon-image', iconValue)
    } else {
      this.mapgl.setLayoutProperty('vehicles', 'icon-image', iconDefaultValue)
    }
  }
}

<template>
  <div>
    <div>
      <simple-button
        :mapgl="mapgl"
        :module="module"
        :layer-id="layerId"
        icon="bus-hollow"
        title="Транспорт"
        :add-handler="addHandler"
        :layers-ids="layersIds"
      />
    </div>
    <mt-popup :settings="popupSettings" />
  </div>
</template>

<script>
import SimpleButton from '../buttons/simple-button'
import { VehicleController } from '@/components/monitoring/map/controllers'
import MtPopup from '@/components/monitoring/map/components/popup.vue'

export default {
  components: {
    MtPopup,
    SimpleButton
  },
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    module: {
      type: String,
      required: true
    },
    vehicleCategoryId: {
      type: String,
      default: 'all_vehicles'
    }
  },
  data() {
    return {
      data: null,
      timer: null,
      refresh: 50000,
      layersIds: [],
      popupSettingsLeftLag: -580,
      popupSettings: {
        display: 'none',
        top: 0,
        left: 0,
        values: []
      }
    }
  },
  computed: {
    layerId() {
      return this.vehicleCategoryId || 'all_vehicles'
    }
  },
  watch: {},
  mounted() {
    this.$socket.emit('enter_module', 'monitoring')
  },
  beforeDestroy() {
    this.$socket.emit('enter_module', 'monitoring_exit')
    this.vehicleController.stopVehicleAnimation()
    this.vehicleController.stopSocketConnection()
  },
  methods: {
    async addHandler() {
      this.vehicleController = new VehicleController(this)
      await this.vehicleController.addVehicles()
    }
  }
}
</script>
<style lang="scss">
.vehicle__popup {
  z-index: 99;
  .mapboxgl-popup {
    &-tip {
      border: none !important;
    }

    &-content {
      background-color: var(--bg_surface);
      display: grid;
      align-items: center;
      justify-content: start;
      grid-gap: 0.25rem;
      grid-auto-flow: row;
      padding: 0;
      border-radius: var(--border-radius);
      border: 1px solid transparent;
      border-color: var(--dividers_low_contrast) !important;

      dl {
        padding: 0.25rem 0.5rem;
        margin: 0;
      }

      dt {
        margin: 0;
        color: var(--text_secondary);
        font-size: 12px;
      }

      dd {
        font-weight: bold;
        margin: 0;
        color: var(--text_primary);
      }
    }
  }
}
</style>
